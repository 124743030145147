<template>
  <article>
    <MediaItem
      :image="this.image"
      :video="video"
      :aspect="( 300 / 522)"
      :showButton="showButton"
      :buttonSide="buttionTypeClass"
      :class="showPointerClass"
      @click.native="onClick"
      :useImageofSize="$imageSizes.medium"
    />
    <h3 :aria-hidden="!work.acf.tag">{{ work.acf.tag || '&nbsp;' }}</h3>
    <h2 :aria-hidden="!work.title.rendered" >
      <span
        :class="`text-link ${showPointerClass}`"
        @click="onClick"
      >
        {{ work.title.rendered || '&nbsp;' }}
      </span>
    </h2>
  </article>
</template>

<script>
import MediaItem from './MediaItem'
import ImageSizes from '../mixins/ImageSizes'
import Wordpress from '../mixins/Wordpress'

export default {
  mixins: [ImageSizes, Wordpress],
  props: {
    work: {
      type: Object,
      required: true
    }
  },
  computed: {
    image () {
      return this?.work?.acf?.image || undefined
    },

    video () {
      return this?.work?.acf?.video || undefined
    },

    showButton () {
      return true
      // Not really sure what this was doing - we just need this to be always true now
      // Show play for buttion only
      // if (
      //   this.$wp.workTypes.modal.vimeo === this.work?.acf?.modal_type &&
      //   this?.work?.acf?.vimeo && 
      //   this?.work?.acf?.vimeo?.embed_url
      // ) {
      //   return true
      // }
      // return false
    },
    buttionTypeClass () {
      if (
        this.$wp.workTypes.modal.vimeo === this.work?.acf?.modal_type &&
        this?.work?.acf?.vimeo && 
        this?.work?.acf?.vimeo?.embed_url
      ) {
        return 'button-center-big'
      }

      if (
        this.$wp.workTypes.modal.photography === this.work?.acf?.modal_type &&
        this.work?.acf?.photography !== undefined &&
        this.work?.acf?.photography.length > 0
      ) {
        return 'button-center-big-images'
      }

      return 'button-blank' 
    },

    showPointerClass () {
      // This is what this fn was based on before we had 2 modal types
      // return (this?.work?.acf?.vimeo && this?.work?.acf?.vimeo?.embed_url) ? 'link-pointer' : 'link-auto'
      if (
        this.$wp.workTypes.modal.vimeo === this.work?.acf?.modal_type &&
        this?.work?.acf?.vimeo && 
        this?.work?.acf?.vimeo?.embed_url
      ) {
        return 'link-pointer'
      }

      if (
        this.$wp.workTypes.modal.photography === this.work?.acf?.modal_type &&
        this.work?.acf?.photography !== undefined &&
        this.work?.acf?.photography.length > 0
      ) {
        return 'link-pointer'
      }
      return 'link-auto'
    }
  },
  methods: {
    onClick: function () {
      if (
        (
          this.$wp.workTypes.modal.vimeo === this.work?.acf?.modal_type &&
          this?.work?.acf?.vimeo && 
          this?.work?.acf?.vimeo?.embed_url
        ) || (
          this.$wp.workTypes.modal.photography === this.work?.acf?.modal_type &&
          this.work?.acf?.photography !== undefined &&
          this.work?.acf?.photography.length > 0
        )
      ) {
        this.$emit('click')
      } else {
        console.warn('@click ~expected warning not bug~', 'non supported modal type or missing images or video to show')
      }
    }
  },

  components: {
    MediaItem
  }
}
</script>
<style lang="scss" scoped>
@import '../styles/text';
@import '../styles/grid';
@import '../styles/theme';
@import '../styles/variables';

article {
  width: d-grid(4, 24px);
  margin-right: 12px;
  margin-left: 12px;
  cursor: default;

  @media (max-width: 800px) {
    width: d-grid(6, 24px);
    margin-right: 12px;
    margin-left: 12px;
  }

  @media (max-width: 550px) {
    width: 100%;
    @include m-margin(0.5);
  }
}

h3 {
  @include b3;
  margin: 0px;
  margin-top: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  opacity: 0.6;
  font-weight: 400;
}

h2 {
  @include b1;
  margin: 0px;
   margin-top: 8px;
  .link-pointer {
    cursor: pointer;
  }
}

/* Text hover effect only when there is a video */
@include link-text-hover-underline('h2 span.link-pointer', 0px, inherit, inherit);

.img-wrapper {
  width: 100%;
  padding-bottom: (9 / 16) * 100%;
  overflow: hidden;
  height: 0;
  position: relative;
  margin-bottom: 24px;
}
@include theme('dark') {
  .img-wrapper {
    background-color: $dark-placeholder;
  }
}
@include theme('light') {
  .img-wrapper {
    background-color: $light-placeholder;
  }
}

img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @supports (object-fit: cover) {
    height: 100%;
    object-fit: cover;
  }
}
svg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  article:hover & {
    opacity: 1;
    visibility: visible;
  }
}
</style>
