<template>
  <div class="container" v-if="works && Array.isArray(works)">
      <WorkTile
        v-for="work in works"
        :key="work.id"
        :work="work"
        @click="openVideoModal(work)"
      />
  </div>
</template>

<script>
import Wordpress from '../mixins/Wordpress'
import WorkTile from './WorkTile'

export default {
  props: {
    works: {
      default: () => [],
      type: Array
    },
    openModal: {
      type: Function,
      required: true
    }
  },
  mixins: [Wordpress],

  methods: {
    openVideoModal: function (work) {
      this.openModal(work)
      // if (!work.acf.vimeo) {
      //   return
      // }
      // this.openModal(work.acf.vimeo)
    }
  },

  components: {
    WorkTile
  }

}
</script>

<style lang="scss" scoped>
@import '../styles/variables';
@import '../styles/container';
@import '../styles/grid';
@import '../styles/buttons';
@import '../styles/text';
@import '../styles/theme';

.container {
  @extend %container;
  @extend %mobile-container;

  display: flex;
  flex-flow: row wrap;

  /* margin-bottom: 130px; */
  @media (max-width: 800px) {
    /* margin-bottom: 25px; */
  }
}

article {
  margin-bottom: 56px;
}

h2 {
  @include b1;
}

</style>
