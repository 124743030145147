<template>
  <transition name="fade">
    <div
      class="modal-transition-container"
      v-if="active && images"
     
    >
      <div 
        class="modal" 
        @click.self="$emit('close')"
        ref="modal"
      >
        <div 
          class="container" 
          @click.self="$emit('close')"
        >
          
          <!-- The Carosel and image chooser -->
          <div class="image-box" ref="imageBox">

              <!-- Carosel - Main Image viewer -->
              <Carousel
                ref="carousel"
                :navigationEnabled="false"
                :perPage="1"
                :paginationEnabled="false"
                :touchDrag="true"
                :navigateTo="activePage"
                @pageChange="onPageChange"
                :style="{
                  height: imageBoxHeight +'px',
                  width: imageBoxWidth + 'px'
                }"
              >
                <Slide
                  v-for="(image, index) in images" :key="`slide-${index}`"
                >
                  <div 
                    class="slide-sizer"
                    :style="{
                      height: (imageBoxHeight) +'px',
                      width: (imageBoxWidth) + 'px'
                    }"
                  >
                    <img 
                      class="image-item"
                      :src="image.image.url"
                      :alt="image.image.alt"
                    />
                  </div>
                </Slide>
              </Carousel>

              <!-- Carosel - Image chooser -->
              <div 
                class="chooser"
                :style="{
                  height: (chooserHeight) +'px',
                }"
              >
                <button class="chooser-item"
                  v-for="(image, index) in images" :key="`chooser-${index}`"
                  @click="() => onImageClickChangeLocation(index)"
                  :style="{
                    height: (chooserHeight) +'px',
                    width: (chooserHeight) +'px',
                  }"
                >
                  <img 
                    class="image-item"
                    :src="image.image.url"
                    :alt="`Click to view image ${index}`"
                  />
                </button>
              </div>

          </div>

          <div class="custom-navigation">
            <div class="buttion-grace buttion-grace-previous">
              <button 
                class="previous"
                @click="onPrevious"
                :disabled="canAdvanceBackward === false && images.length > 1"
              >
                <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M33 65C15.3269 65 1 50.6731 1 33C1 15.3269 15.3269 1 33 1C50.6731 1 65 15.3269 65 33C65 50.6731 50.6731 65 33 65Z" stroke="white" stroke-width="2"/>
                  <path d="M41 17L19 33L41 49" stroke="white" stroke-width="2"/>
                  <path d="M33 65C15.3269 65 1 50.6731 1 33C1 15.3269 15.3269 1 33 1C50.6731 1 65 15.3269 65 33C65 50.6731 50.6731 65 33 65Z" stroke="white" stroke-width="2"/>
                </svg>

              </button> 
            </div>
            <div class="buttion-grace buttion-grace-next">
              <button 
                class="next"
                :disabled="canAdvanceForward === false && images.length > 1"
                @click="onNext"
              >
                <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M33 65C50.6731 65 65 50.6731 65 33C65 15.3269 50.6731 1 33 1C15.3269 1 1 15.3269 1 33C1 50.6731 15.3269 65 33 65Z" stroke="white" stroke-width="2"/>
                  <path d="M25 17L47 33L25 49" stroke="white" stroke-width="2"/>
                  <path d="M33 65C50.6731 65 65 50.6731 65 33C65 15.3269 50.6731 1 33 1C15.3269 1 1 15.3269 1 33C1 50.6731 15.3269 65 33 65Z" stroke="white" stroke-width="2"/>
                </svg>
              </button>
            </div>
          </div>

        <div/>

        <!-- 
          Close Button
          At the bottom so we still get the click events with out any hacks
        -->
        <svg 
          class="close-button" 
          width="32" height="32" 
          viewBox="0 0 32 32" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          @click="$emit('close')"
        >
          <g>
            <path class="themed-close-cross" fill-rule="evenodd" clip-rule="evenodd" d="M15.9998 29.3334C23.3636 29.3334 29.3332 23.3639 29.3332 16.0001C29.3332 8.63628 23.3636 2.66675 15.9998 2.66675C8.63604 2.66675 2.6665 8.63628 2.6665 16.0001C2.6665 23.3639 8.63604 29.3334 15.9998 29.3334Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path class="themed-close-cross" d="M20 12L12 20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path class="themed-close-cross" d="M12 12L20 20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
        </svg>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import CloseButton from './CloseButton'
import { Carousel, Slide } from 'vue-carousel'

export default {
  props: {
    active: Boolean,
    images: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      imageBoxHeight: 0,
      imageBoxWidth: 0,
      chooserHeight: 0,
      activePage: 1,
      canAdvanceForward: true,
      canAdvanceBackward: false

    }
  },
  watch: {
    active: function (newVal, prevVal) {
      // On change hide/show the scroll
      document.body.style.overflowY = (newVal) ? 'hidden' : 'auto'
      document.documentElement.style.overflowY = (newVal) ? 'hidden' : 'auto'
      // When avtive get the screen size and set the size of the carosel
      // Make sure we wait till the ref is there after the inital render
      // Otherwise ref is empty and we get a 0 as a size = nothing on screen
      // We also need to re-set to index 0 because otherwise we show the index of the last image
      this.$nextTick(() => {
        if (newVal === true) {
          this.onImageBoxSizeChange()
          this.onImageClickChangeLocation()
          this.canAdvanceForward = true
          this.canAdvanceBackward = false
        }
      })
    }
  },
  
  // Debug for when we force open and the watcher is never called
  mounted: function () {
    document.body.style.overflowY = (this.active) ? 'hidden' : 'auto'
    document.documentElement.style.overflowY = (this.active) ? 'hidden' : 'auto'

    // Set up image sizing
    window.addEventListener('resize', this.onImageBoxSizeChange)
  },

  destroyed: function () {
    // Make sure we clean up - no zombies
    window.removeEventListener('resize', this.onImageBoxSizeChange)
  },
  methods: {
    onPageChange (val) { // Val in this case is hwo many it has moved
      this.canAdvanceForward = this.$refs?.carousel?.canAdvanceForward
      this.canAdvanceBackward = this.$refs?.carousel?.canAdvanceBackward
      console.log('onPageChange',
        this.$refs?.carousel?.canAdvanceForward,
        this.$refs?.carousel?.canAdvanceBackward
      )
    },
    // A fn to claulate the size of the carosel and image chooser
    onImageBoxSizeChange: function () {
      // This won't run correctly if the image box is not their
      if (this.$refs.imageBox !== undefined && this.$refs.modal !== undefined) {
        const boxHeight = this.$refs.imageBox.getBoundingClientRect().height
        const boxWidth = this.$refs.imageBox.getBoundingClientRect().width
        let chooserHeight = 0
        // Make sue we have good sizes for desktop and mobile
        const theActualScreenWidth = this.$refs.modal.getBoundingClientRect().width
        // console.log('@theActualScreenWidth', theActualScreenWidth)
        if (theActualScreenWidth > 800) {
          chooserHeight = boxWidth / 12 * 1
        } else {
          chooserHeight = boxWidth / 5 * 1
        }
        // Flash mesurments to the state so we can use it
        this.imageBoxHeight = boxHeight - chooserHeight - 2 // a 2 px gab betwean items
        this.imageBoxWidth = boxWidth
        this.chooserHeight = chooserHeight 
      }
    },
    // A fn for updating the carsels position when the user clicks an image item
    onImageClickChangeLocation: function (index) {
      // we have to toggle the value becuase state managment won't see an update
      this.activePage = undefined
      setTimeout(() => {
        this.activePage = index
      }, 100)
    },
    onNext: function () {
      console.log('this.$refs.carousel', this.$refs.carousel)
      this.$refs.carousel.handleNavigation()
    },

    onPrevious: function () {
      console.log('this.$refs.carousel', this.$refs.carousel)
      this.$refs.carousel.handleNavigation('backward')
    }
  },
  components: {
    Carousel, 
    Slide
  }
}
</script>

<style lang="scss" scoped>
@import "./../styles/theme";
@import "./../styles/variables";
@import "./../styles/buttons";

@include themed-close-cross;
.modal-transition-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
    margin-right: -4px;
    margin-top: -4px;
    .close-button {
      position: absolute;
      top: 48px;
      right: 4.16667%;
      cursor: pointer;
      @media (max-width: 800px) {
        top: 24px;
        right: 26px;
      }
      @media (max-width: 900px) and (max-height: 500px)  {
        display: none;
      }
    }
  }
}

/* 
  * A bit of a fast hack to get the buttions to work 
  * Due to the overflow used for getting everything to sit 
  * we can't use the built in next and back buttions as they 
  * get cut off
  */
.custom-navigation {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 1440px;
  transform: translate(-50%, -50%);
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-left: 32px;
  padding-right: 32px;

  pointer-events: none;
  touch-action: none;

  @media (max-width: 800px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .buttion-grace {
    // Provide a grace area around the button
    pointer-events: all;
    touch-action: all;

    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    button { 
      @extend %safe-clear-button;
      
      flex-grow: 0;
      flex-shrink: 0;
      background: transparent;

      cursor: pointer;

      @media (max-width: 800px) {
        display: none;
      }

      &:disabled {
        cursor: default;
        opacity: 0;
      }

      svg {
        width: 40px;
        height: 40px;

        @media (max-width: 1000px) {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}

.image-box {
  width: 100%;
  // Make sure we can see the arrows
  max-width: calc(1440px - 360px); 
  position: relative; // Makes sure that everything sits on top of the close box
  overflow: hidden;
  flex-grow: 1;
  /* background: blue; */
  .VueCarousel {
    .VueCarousel-slide{
      &:hover {
        cursor: grab;
      }
      &:active:hover {
        cursor: grabbing;
      }
    }

    .slide-sizer {
      // Width and height are controlled
      /* background: green; */
      .image-item {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .chooser {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    // Height is controlled
    margin-top: 2px;
    
    .chooser-item {
     // Width and height are controlled
      flex-shrink: 0;
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  /* border: 1px solid green; */

  padding: 80px;
  padding-top: 87.5px;
  padding-bottom: 87.5px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    padding: 32px;
    padding-top: 24px;
  }
  @media (max-width: 900px) and (max-height: 500px)  {
    padding: 32px;
    padding-top: 24px;
  } 
}

@include theme('light') {
  .modal {
    @include theme-transition;
    background-color: rgba(#fff, .9);
    color: black;
  }
  .video {
    @include theme-transition;
    // background-color: $light-placeholder;
  }

  .custom-navigation {
    path {
      stroke: black;
    }
  }
}

@include theme('dark') {
  .modal {
    @include theme-transition;
    background-color: rgba(#000, .9);
    color: white;
  }
  .video {
    @include theme-transition;
    // background-color: $dark-placeholder;
  }

  .custom-navigation {
    path {
      stroke: whtie;
    }
  }
}

$transition-time: 0.3s;

.fade-enter-active, .fade-leave-active {
  transition: opacity $transition-time ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
